import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../../../components/mdx/page";
import { Breadcrumbs } from "../../../../../components/widgets/breadcrumbs";
import { Aside } from "../../../../../components/widgets/aside";
import { Border } from "../../../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs to="/company/newsletter/" text="Newsletter Archive" mdxType="Breadcrumbs" /> 
    <p>{`Hello TuringTrader Member,`}</p>
    <p>{`Cheers to a happy, healthy, and prosperous 2023! After a tumultuous 2022, we hope to see the markets settle on their direction, allowing the strategies to make better choices. But, of course, the main driver will be inflation, which will dictate Fed policy and the economic outlook.`}</p>
    <p>{`To close the year, we spent some time analyzing the performance of our portfolios, how they tracked their benchmarks, and how well the backtests represented performance achieved in real-life trading. Spoiler alert: while performance wasn’t pretty, we firmly believe that our portfolios added value in 2022 and are well-positioned for 2023. For full insight, we recommend reading our `}<a parentName="p" {...{
        "href": "/articles/review-2022/"
      }}>{`detailed article`}</a>{`.`}</p>
    <p>{`As always, I would like to thank everybody who reached out over the past month with questions, comments, and suggestions. It’s an honor to have you on our site. If you like what we do, support our growth by sharing how `}<a parentName="p" {...{
        "href": "/"
      }}>{`TuringTrader`}</a>{` adds value for you with your friends.`}</p>
    <p>{`Stay tuned for further updates,`}<br />{`
All the best`}</p>
    <p>{`Felix`}<br />{`
--`}<br />{`
Felix Bertram`}<br />{`
Founder of TuringTrader.com`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      